<template>
    <div class="container hcpPrivacyPolicyMainDiv">
        <div class="hcpPrivacyPolicy">
            <h2>RxIx Privacy Policy</h2>
        </div>
        <p class="mt-4">
            This Privacy Policy governs how we, Bamboo Wellness Solutions LLP, a Limited Liability Partnership registered
            under the Limited Liability Partnership Act, 2008, which expression shall unless it be repugnant to the context
            or meaning thereof, be deemed to mean and include, its successors and assigns, whose registered office is at FG,
            Block 2, Jains Ashraya Phase 1, 1A Vembuliamman Koil Street, West KK Nagar, Chennai - 600078 and head office is
            at NSIC STP Complex, No. 403 & 404, 3rd Floor Business Park, B-24, Guindy Industrial Estate, Ekkatuthangal,
            Chennai, Tamil Nadu 600032 (collectively, “Company”, “we,” “us,” or “our”) collect, use, share and process your
            information, that you provide to us through your use of the Site [https://rxix.in/] in the course of availing
            services (“End User Services” and “HCP Services”) that are made available on the said Site as defined in the <a
                href="https://www.rxix.in/policiesandterms" target="_blank">End User Terms and Conditions and HCP Terms and Conditions</a>
            to you.
        </p>
        <p class="py-2">
            The Company, owner of the rxix.in website (“Site”), respects your privacy, and seeks to comply with applicable
            legal requirements, including the Information Technology Act, 2000, in respect of data collection, processing
            and transfer.
        </p>
        <p class="py-2">
            Please read this Privacy Policy carefully. By accessing or using this Site, you agree to be bound by the terms
            described herein and all the terms incorporated by reference. If you do not agree to all of these terms, do not
            use this Site.
        </p>
        <div>
            <p class="pricavyPolicySubheading">1. What is Personal Information?</p>
            <p class="py-2">
                Personal information is that information which can be used to directly or indirectly identify you. It
                includes de-identified data that, when linked to other information available to us, would enable us to
                identify you. Personal data does not include data that has been irreversibly anonymised or aggregated so
                that we cannot identify you through it, even in conjugation conjunction with other information.
            </p>
            <p class="py-2">
                “Sensitive Personal Data or Information” means personal information of any individual relating to password;
                financial information, such as bank account or credit card or debit card or other payment instrument
                details; physical, physiological and mental health condition; sexual orientation; health information, such
                as medical records and history; biometric information; any detail relating to the above as provided to or
                received by us for processing or storage. However, any data / information relating to an individual that is
                freely available or accessible in public domain or furnished under the Right to Information Act, 2005 or any
                other law shall not qualify as Sensitive Personal Data or Information.
            </p>
            <p class="py-2">
                By signing up on the Site, and / or using our Services you represent that you voluntarily provide us with
                personal information including medical and financial information and consent to their collection, use and
                disclosure in accordance with this Privacy Policy. You also represent that you are duly authorised by any
                third party (including a child or an employee) whose information you share with us. We shall act as per your
                representation of authority and shall not make any independent enquiries to ascertain the veracity of your
                authorisation. In the event you do not have sufficient authorisation, you shall be solely responsible for
                your acts and omissions including sharing of information with us by you and the consequential processing and
                actions taken by us in accordance with this Privacy Policy.
            </p>
            <p class="pricavyPolicySubheading">2. What types of data do we collect?</p>
            <p class="py-2">
                The Site, like many other websites uses “Cookies”. Cookies are small data files that a website stores on
                your web browser. These are used for the purpose of storing your preferences, previous activities browsing
                activities, profiling and tracking behaviour on this Site. By visiting the Site, you acknowledge, accept and
                expressly authorize the placement of cookies on your web browser. We recommend that you clear the cookies
                stored on your browser from time to time.
            </p>
            <p class="py-2">
                When you sign up or register on the Site to use our Services, and during the course of actual usage of our
                Services, the types of information that will be collected by us include the following:
            </p>
            <ul>
                <li>Contact information: Name, Address, Contact details, Email ID, Phone Number;</li>
                <li>Demographic information: Gender, Date of Birth, Nationality;</li>
                <li>Data regarding your usage of the Services, such as search history and history of the appointments made
                    by you through the use of Services;
                </li>
                <li>Financial information, such as bank account or credit card or debit card or other payment instrument
                    details and billing information;
                </li>
                <li>Your browsing history, including the URL of the site that you visited prior to visiting the Site as well
                    as the Internet Protocol (IP) address of your computer (or the proxy server you used to access the World
                    Wide Web), your computer operating system and the type of web browser you are using, the name of your
                    ISP;
                </li>
                <li>Any additional information that you provide to us during the use of the Services, through any mode of
                    communication or during any interaction with our employees, the HCPs registered on the Site, etc.;
                </li>
                <li>Health information, such as your medical records and history which you voluntarily provide or is
                    generated on usage of any of the services availed by you from any clinic using the RxIx Site;
                </li>
                <li>Information regarding your insurance coverage (such as your insurance carrier and insurance plan) which
                    you voluntarily provide or is generated on availing any of the Services;
                </li>
                <li>Information regarding your physical, physiological and mental health condition that you voluntarily
                    provide or is generated on the usage of the Services;
                </li>
                <li>Any other information that is collected or generated in the course of availing the Services;
                </li>
                <li>Data regarding your educational qualifications, registrations and certifications;
                </li>
                <li>Any other detail relating to the above as voluntarily provided to us by you, for providing value added
                    service; and
                </li>
                <li>Any other information relating to the above which you may have shared with us prior to this Privacy
                    Policy for availing any of the Services.</li>
            </ul>
            <p class="pricavyPolicySubheading">3. Where do we collect your data from?</p>
            <p class="py-2">
                For end users:
            </p>
            <ul>
                <li>Any information that you voluntarily choose to provide to us through the Site during interaction with us
                    on call or chat and other modes of communication;
                </li>
                <li>
                    Information that we collect from healthcare providers (“HCPs”), to whom you have permitted the sharing
                    of your personal information.
                </li>
                <li>
                    Data you have provided to any group company of the Company, affiliates, associates, subsidiary, holding
                    company of the Company, associates and subsidiaries of holding company of the Company, to whom you have
                    given consent for sharing of such information.
                </li>
            </ul>
            <p class="py-2">
                For HCPs:
            </p>
            <ul>
                <li>
                    Before on boarding you, we may request information regarding your qualifications, experience, public
                    profile, and representations made by you to us;
                </li>
                <li>
                    During your usage of the Site, we may collect information based on your use of the Services;
                </li>
                <li>
                    We may collect other information that you voluntarily choose to provide to us through the Site, email,
                    during interaction with us on call or chat and other modes of communication;
                </li>
                <li>
                    We may collect information from any group company, affiliates, associates, subsidiary, holding company
                    of the Company, associates and subsidiaries of holding company of the Company to whom you have given
                    consent for sharing of information for availing value-added service.
                </li>
            </ul>
            <p class="pricavyPolicySubheading">4. How do we use your data?</p>
            <p class="py-2">
                We use your personal information for purposes that include the following:
            </p>
            <p class="py-2">
                General (end users and HCPs):
            </p>
            <ul>
                <li>Your registration for the purpose of receiving our Services, identification, communication, notification
                    and for fulfilment of the <a href="https://www.rxix.in/policiesandterms" target="_blank">Terms and Conditions</a>
                </li>
                <li>Offering you personalized Services and targeted advertisements of various healthcare and wellness plans
                    and offering you customised health insights;</li>
                <li>Addressing your requests, queries and complaints, if any, pertaining to our Services; taking feedback,
                    assisting you with completion of transactions or other issues relating to the use of Services and other
                    customer care related activities;</li>
                <li>Leveraging services from our group companies and customising suggestions for appropriate medical
                    products and services;</li>
                <li>Creating insights for corporate / business strategy and marketing operations of our group companies;
                </li>
                <li>Developing machine learning algorithms and tools to improve targeting of services, diagnostics and
                    treatment protocols and other products and services;</li>
                <li>Contacting you to provide information on new Services, features, products, special promotions or offers,
                    both of the Apollo group entities and affiliates as well as third-party offers or products with whom we
                    have a tie-up and which are relevant to use of the Services;</li>
                <li>Technical administration and customization of Website, and other general administrative and business
                    purposes;
                </li>
                <li>Research and analysis for the development and improvement of products and services;
                </li>
                <li>Disclosure as required to government authorities in compliance with applicable law;
                </li>
                <li>Carrying out our obligations in relation to any agreement with affiliate companies, our group companies,
                    our business partners or contractors;
                </li>
                <li>nvestigating, enforcing and resolving any disputes or grievances; and</li>
                <li>Any other purpose required by applicable law.</li>
            </ul>
            <p class="py-2">
                For end users only:
            </p>
            <ul>
                <li>Creation and maintenance of health records in electronic form in the Personal Health Record (PHR)
                    database for use by you, by us and our group companies, affiliates, etc., to provide relevant services;
                </li>
                <li>Create your unified profile with analytics and insights generated through processing your personal
                    information;
                </li>
                <li>For sharing with your chosen HCPs registered on RxIx who may provide you services using the Site;
                </li>
                <li>Processing any orders/requests you may place using our Services.</li>
            </ul>
            <p class="py-2">
                For end HCPs only:
            </p>
            <ul>
                <li>For verifying your professional credentials and any representations you have made to us;
                </li>
                <li>For processing any payments made to you;</li>
                <li>For providing recommendations to end users based on your expertise and specialisations ;
                </li>
                <li>For providing any other service to you.</li>
            </ul>
            <p class="pricavyPolicySubheading">5. How long will we retain your data?</p>
            <p class="py-2">
                We store your personal information in accordance with applicable laws, which means we keep your data for as
                long as necessary to provide you with our Services or as may be required under any law. We shall store your
                personal information for lawful purposes only. We keep de-identified data for research and statistical
                purposes for a longer period.
            </p>
            <p class="py-2">
                If you terminate your account, we have no obligation to retain your data, and we may delete any or all of
                your data without liability. However, we may retain data related to you if we believe it may be necessary to
                prevent fraud or future abuse, or if required by law, or for other legitimate purposes. We may continue to
                store your data in anonymised or de-identified form for analytical, research or other purposes for which
                your information is collected as previously indicated.
            </p>
            <p class="pricavyPolicySubheading">6. Disclosure and transfer of your data</p>
            <p class="py-2">
                We may share, disclose and, in some cases, transfer your personal information to such entities, as required
                to provide Services to you, improve our Services, and to provide value-added services or other third-party
                products and services, to the extent permitted by applicable law. These entities may be located outside
                India, which you hereby consent to. We require such entities to protect your information through equivalent
                security measures, as what we would adopt. An indicative list of entities we may disclose or transfer
                information to, are provided below:
            </p>
            <p class="py-2">
                a. Service Providers: We share personal information with companies that provide Services on our behalf, such
                as website hosting, data storage, software services, email services, marketing, fulfilling end user and HCP
                requests, providing payment-related services, including payment aggregation, data analytics, data mining,
                providing end user services, and conducting surveys, as permitted by applicable law. These companies may be
                located within or outside India, but in any case are obligated to protect your data.
            </p>
            <p class="py-2">
                We may also share information with employees, data processors, consultants, business partners and technology
                partners on a need-to-know basis. Such entities would be contractually obligated to maintain confidentiality
                in relation to your data. If you are an end user, your personal information will also be shared with your
                chosen HCPs.
            </p>
            <p class="py-2">
                b. Business Affiliates: We may disclose or transfer some of your information to entities in our
                group companies, affiliates, associates, subsidiary, holding company of the Company, associates and
                subsidiary of holding company of the Company including foreign entities, and, in particular, group companies
                and affiliates who are involved in the provision of products and services, to the extent permitted by
                applicable law.
            </p>
            <p class="py-2">
                In the event of a merger, reorganization, acquisition, joint venture, assignment, spin-off, transfer, asset
                sale, or sale or disposition of all or any portion of our business, including in connection with any
                bankruptcy or similar proceedings, we may transfer any and all personal information to the relevant third
                party with the same rights of access and use.
            </p>
            <p class="py-2">
                c. Law Enforcement Agencies: We may share information with law enforcement agencies pursuant to lawful
                requests for information, and otherwise as required under any law applicable at the given time, both in
                India and outside India.
            </p>
            <p class="py-2">
                d. Other Third Parties:
            </p>
            <ul>
                <li>We may also disclose personal information if we determine in good faith that disclosure is reasonably
                    necessary to protect our rights and pursue available remedies, enforce our terms and conditions,
                    investigate fraud, or protect our operations or users.
                </li>
                <li>We may disclose personal information to any third party, if necessary to provide or improve our
                    Services, fulfill any lawful contractual obligation we are bound by, and any other activity related to
                    the purposes identified in this privacy policy and the terms and conditions you agree to when you use
                    our Services.
                </li>
                <li>Anonymised, aggregated data may be shared with advertisers, research firms and other partners.
                </li>
            </ul>
            <p class="pricavyPolicySubheading">7. How do we protect your data?</p>
            <p class="py-2">We are committed towards maintaining the privacy of the information uploaded by you on the Site
                and complying with the industry-standard security safeguards for the purpose of securing the Site and the
                information provided / uploaded by you.
            </p>
            <p class="py-2">
                We use reasonable technical, administrative, and physical security measures for the purpose of safeguarding
                all data you share with us. We also have comprehensive internal policies in place to prevent unauthorized
                access to your data. We take adequate steps to ensure that third parties we share data with also adopt
                reasonable level of security practices and procedures to ensure the privacy and security of your
                information.
            </p>
            <p class="py-2">
                However, we are not responsible for any loss, unauthorised access, safety issue or any harm caused to you by
                any misuse of your personal information, unless it is a direct and foreseeable consequence of negligence and
                non-compliance on our part only. You hereby acknowledge that we are not responsible, in particular, for any
                third-party action or action on your part leading to loss, damage or harm to you or any other person.
            </p>
            <p class="py-2">
                For any data loss or theft due to unauthorized access to your electronic devices through which you avail our
                Services, the Company shall not be held liable for any loss whatsoever incurred by you. Further, you are
                liable to indemnify the Company as per the Terms of Use.
            </p>
            <p class="pricavyPolicySubheading">8. What are your rights?</p>
            <p class="py-2">
                We take reasonable steps to ensure that your personal information is accurate, complete, and up
                to date. However, you have the sole responsibility of ensuring that you review the accuracy of the
                information provided by you and contact us in case of discrepancies, or in case you wish to discontinue the
                use of our Services. You have the following rights with regard to your personal information:
            </p>
            <ul>
                <li>You have the right to access your personal information, correct and delete. If your personal information
                    changes, you may correct, delete inaccuracies, or amend information by making the change on your
                    Settings page or by contacting us through the Contact Us page. We will make good faith efforts to make
                    requested changes in our then active databases as soon as reasonably practicable. If you provide any
                    information that is untrue, inaccurate, out of date or incomplete (or subsequently becomes untrue,
                    inaccurate, out of date or incomplete), or we have reasonable grounds to suspect that the information
                    provided by you is untrue, inaccurate, out of date or incomplete, we may, at our sole discretion,
                    discontinue the provision of the Services to you. There may be circumstances where we will not correct,
                    delete or update your personal information, including (a) where the personal information is opinion data
                    that is kept solely for evaluative purpose; (b) the personal information is in documents related to a
                    prosecution if all proceedings relating to the prosecution have not been completed; and(c) where your
                    information has already been processed in de-identified form.
                </li>
                <li>You are free to not share any medical or other information that you consider confidential and withdraw
                    consent for us to use data that you have already provided. In the event that you refuse to share any
                    information or withdraw consent to process information that you have previously given to us, we reserve
                    the right to restrict or deny the provision of our Services for which we consider such information to be
                    necessary.
                </li>
                <li>You may contact Mr. V Rameshwar at privacy@rxix.in for any questions or for exercise of these rights. We
                    will respond to your request within a reasonable time.</li>
            </ul>
            <p class="pricavyPolicySubheading">9. Third Party Websites and Services</p>
            <p class="py-2">Our Site may contain links to third party services, and give you the ability to access such
                third-party websites, products, and services. Please note that you may proceed to the use of such
                third-party website or service at your own risk and the Company will not be held liable for any outcome or
                harm arising as a result of your use of such third party websites or services. Please read the privacy
                policies of any third party before proceeding to use their websites, products, or services.
            </p>
            <p class="pricavyPolicySubheading">10. Changes to this Privacy Policy</p>
            <p class="py-2">Any changes to our Privacy Policy will be posted on the Site and will become effective as of the
                date of posting. Please review the Privacy Policy from time to time to make sure you are aware of any
                changes. If you do not agree with any such revised terms, please refrain from using our Services and contact
                us to terminate any account you may have created.</p>
        </div>
    </div>
</template>
<script>
export default ({
  components: {
  },
  data() {
    return {}
  }
})
</script>
<style>
.hcpPrivacyPolicyMainDiv {
    text-align: justify;
}

.hcpPrivacyPolicy {
    font-size: 22px;
    color: black;
    font-weight: 550;
    margin-top: 5rem;
}

.pricavyPolicySubheading {
    font-size: 19px;
    color: black;
    font-weight: 550;
}
@media screen and (max-width:991px) {
    .hcpPrivacyPolicy {
        margin-top: 2rem;
    }
}
</style>